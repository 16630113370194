export type Plural = [string, string]
export type Declension = [string, string, string]

export const doctorDeclension: Declension = ['врач', 'врача', 'врачей']
export const doctorPlural: Plural = ['врача', 'врачей']

export const clinicDeclension: Declension = ['клиника', 'клиник', 'клиник']
export const clinicPlural: Plural = ['клиники', 'клиник']

export const wordBestMaleDeclension: Declension = ['лучший', 'лучших', 'лучших']

export const wordBestFemaleDeclension: Declension = ['лучшая', 'лучшие', 'лучших']
